module.exports = {
  2005: {
    num_facilities: 29528,
    num_chemicals: 512,
    total: 4466731250,
    total_on_site: 3842246672,
    total_off_site: 624484521,
    total_air: 1519948677,
    total_water: 254655703,
    total_land: 5894640443,
    total_carcinogen: 241775699,
  },
  2006: {
    num_facilities: 29528,
    num_chemicals: 519,
    total: 4456251322,
    total_on_site: 3801823721,
    total_off_site: 654420627,
    total_air: 1418996726,
    total_water: 250594699,
    total_land: 5929795387,
    total_carcinogen: 247194158,
  },
  2007: {
    num_facilities: 29528,
    num_chemicals: 498,
    total: 4251128714,
    total_on_site: 3580454170,
    total_off_site: 670674457,
    total_air: 1336061637,
    total_water: 239066637,
    total_land: 5577201268,
    total_carcinogen: 221072635,
  },
  2008: {
    num_facilities: 29528,
    num_chemicals: 491,
    total: 3977377428,
    total_on_site: 3401636402,
    total_off_site: 575740982,
    total_air: 1154797328,
    total_water: 247192204,
    total_land: 5375553321,
    total_carcinogen: 190978664,
  },
  2009: {
    num_facilities: 29528,
    num_chemicals: 488,
    total: 3436785468,
    total_on_site: 3038766094,
    total_off_site: 398019256,
    total_air: 925985561,
    total_water: 206371160,
    total_land: 4939889076,
    total_carcinogen: 152624754,
  },
  2010: {
    num_facilities: 29528,
    num_chemicals: 496,
    total: 3849515331,
    total_on_site: 3401851784,
    total_off_site: 447659290,
    total_air: 863852418,
    total_water: 232568543,
    total_land: 5699765397,
    total_carcinogen: 187004896,
  },
  2011: {
    num_facilities: 29528,
    num_chemicals: 513,
    total: 4150304230,
    total_on_site: 3701677611,
    total_off_site: 448625038,
    total_air: 808535680,
    total_water: 222881158,
    total_land: 6360411519,
    total_carcinogen: 179600921,
  },
  2012: {
    num_facilities: 29528,
    num_chemicals: 502,
    total: 3683211624,
    total_on_site: 3232394767,
    total_off_site: 450816802,
    total_air: 774593941,
    total_water: 218797692,
    total_land: 5462430995,
    total_carcinogen: 194747574,
  },
  2013: {
    num_facilities: 29528,
    num_chemicals: 524,
    total: 4199937541,
    total_on_site: 3727440061,
    total_off_site: 472497411,
    total_air: 775028769,
    total_water: 214267440,
    total_land: 6269202886,
    total_carcinogen: 185926070,
  },
  2014: {
    num_facilities: 29528,
    num_chemicals: 504,
    total: 4037995904,
    total_on_site: 3545661980,
    total_off_site: 492333147,
    total_air: 762910487,
    total_water: 219151712,
    total_land: 6103589614,
    total_carcinogen: 195650015,
  },

  2015: {
    num_facilities: 29528,
    num_chemicals: 499,
    total: 3493906777.6471977,
    total_on_site: 2979752347.0186954,
    total_off_site: 514152720.5778954,
    total_air: 696518707.0277857,
    total_water: 199323548.9045814,
    total_land: 5051006955.159819,
    total_carcinogen: 217911984.6328666,
  },
  2016: {
    num_facilities: 29528,
    num_chemicals: 502,
    total: 3565171745.4497623,
    total_on_site: 3126912006.6277084,
    total_off_site: 438259730.8219621,
    total_air: 619182996.782796,
    total_water: 192567897.4103343,
    total_land: 5439095687.430834,
    total_carcinogen: 201579734.5345728,
  },
  2017: {
    num_facilities: 29528,
    num_chemicals: 499,
    total: 3968622905.0699573,
    total_on_site: 3534183816.491985,
    total_off_site: 434439052.5775382,
    total_air: 617866946.0812894,
    total_water: 191401691.3583003,
    total_land: 6252986254.21573,
    total_carcinogen: 210337332.0023714,
  },
  2018: {
    num_facilities: 29528,
    num_chemicals: 493,
    total: 3771274630.773736,
    total_on_site: 3305264000.581205,
    total_off_site: 466010630.2195683,
    total_air: 623297807.9360095,
    total_water: 195741160.0962302,
    total_land: 5790110225.610629,
    total_carcinogen: 217147064.4142014,
  },
  2019: {
    num_facilities: 29528,
    num_chemicals: 490,
    total: 3432825183,
    total_on_site: 2962522881,
    total_off_site: 470302236,
    total_air: 600028402,
    total_water: 201247773,
    total_land: 5122054952,
    total_carcinogen: 177183320,
  },
};
